<template>
	<div class="home" v-if="data.configs">




		<div>

			<swiper ref="mySwiper" style="min-height: 1rem;" class="w-100 " :options="swiperOptions">
				<swiper-slide class=" h-100" style="min-height: 1rem;" v-for="(item,index) in data.banners" :key="index">
					<div>
						<img class="swiper h-100" style="object-fit: cover" :src="$imgURL+item.image" alt="" srcset="" />
					</div>
				</swiper-slide>

				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<div class="d-none d-lg-block">
				<div class="swiper-button-prev " style="outline: none;"></div>
				<div class="swiper-button-next " style="outline: none;"></div>
			</div>

		</div>


		<!-- 动态 -->
		<div class="subcontent   teacher">
			<div class="home-title">
				<div class="font-md  text-center">{{data.configs.dynamic_name}}</div>
				<div class="font text-center  font-line">{{data.configs.dynamic_eng}}</div>
				<div class="font text-center ">{{data.configs.dynamic_desc}}</div>
			</div>

			<div class="d-block mt-0-5 mt-sm-2 d-sm-none ">
				<el-carousel :interval="500000" height="300px" :autoplay="false" indicator-position="none" arrow="always">
					<el-carousel-item class=" d-flex justify-content-center" v-for="v in data.dynanics">
						<div class="d-flex img-ii  w-100 flex-column align-items-center justify-content-center">

							<el-carousel style="width:200px" indicator-position="none" height="200px" arrow="nerver" @change="dongTaiChange">
								<el-carousel-item v-for="(item,index) in  v.image" :key="item">

									<img style="height: 200px;width:200px;object-fit: cover;" :src="$imgURL+item" alt="" srcset="" />
								</el-carousel-item>
							</el-carousel>

							<div>
								<div class="title pointer font-smd py-0-5 ">
									<div class="text-over   font" style="letter-spacing: 0.01rem;">{{ v.title }}</div>
								</div>
								<div class="progress " style="width: 200px">
									<div class="sub-progress bg-yellow" style="width:25%" :style="{marginLeft: dongTaiDoc+'%'}"></div>
								</div>
							</div>



						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="  d-none d-sm-block">
				<div class="d-flex justify-content-center">
					<div class="  container  mt-2 p-0">
						<div class="d-flex flex-row">
							<div class=" p-0 m-0 col-4" v-for="(v,i) in data.dynanics">
								<div class="img-ii px-0-5">

									<!-- <swiper ref="mySwiper" style="min-height: 1rem;" class="w-100  " :options="dynamicOptions">
										<swiper-slide class=" d-flex justify-content-center   h-100" v-for="(item,index) in  v.image" :key="index">
											<div class=" h-100">
												<img class="w-100 h-100" style="object-fit: cover;" :src="$imgURL+item" alt="" srcset="" />
											</div>
										</swiper-slide> -->
									<el-carousel class=" w-100" indicator-position="none" height="300px" arrow="nerver" @change="docChange($event,i)">
										<el-carousel-item class="w-100 h-100" v-for="(item,index) in  v.image" :key="item">
											<img class="w-100 h-100" style="object-fit: cover;" :src="$imgURL+item" alt="" srcset="" />
										</el-carousel-item>
									</el-carousel>

									<div class="swiper-pagination" slot="pagination"></div>
									</swiper>



									<div class="title font py-0-5" style="letter-spacing: 0.01rem;">{{v.title}}</div>

									<div class="progress ">
										<div class="sub-progress bg-yellow" style="width:25%" :style="{marginLeft: v.doc+'%'}"></div>
									</div>
								</div>
							</div>
						</div>
						<!-- </swiper> -->
					</div>
				</div>

			</div>

		</div>









		<!-- 课程体系 -->
		<div class="subcontent py-2 class">
			<div class="home-title">
				<div class="font-md text-center ">{{data.configs.curriculum_name}}</div>
				<div class="font text-center font-line">{{data.configs.curriculum_eng}}</div>
				<div class="font text-center">{{data.configs.curriculum_desc}}</div>

			</div>
			<div class="d-flex  mt-sm-3 mt-0 mb-sm-4 mb-0 align-items-center  justify-content-center h-sm-5">
				<div class="container   my-2 mt-sm-4   justify-content-center d-flex flex-column   flex-sm-row">
					<div class="col-12    p-0   justify-content-sm-between justify-content-center  col-sm-2 d-flex flex-row flex-lg-column flex-wrap h-sm-5">
						<div class="pointer  w-100-tixi  text-center  font   p-tixi  py-lg-0-5" v-for="(v, i) in data.curriculums" :key="i"
						 @mouseleave="classMouseleave(i)" @mousemove="classMousemove(i)">
							<div :class="[isClassActiveIndex == i ? 'bg-secondary text-white' : 'bg-yellow']" class="bg-yellow py-0-5 py-md-1 px-0-5 px-0 w-100 font-weight-bold "
							 style="letter-spacing: 0.02rem;">
								<div class="text-over">{{ v.title }}</div>
							</div>
						</div>
					</div>
					<div class="col-12 p-0  mt-2 mt-sm-0  px-0-5 px-sm-0  pl-sm-1 pl-0  col-sm-10 tixi-samll h-sm-5">

						<div class="d-flex  align-items-center flex-row h-100  ">
							<div class="col-8 h-100  d-flex justify-content-center p-0 ">
								<img :src="$imgURL+ data.curriculums[isClassActiveIndex].image" class="w-100 h-100" :class="yixiImg?' animate__animated animate__fadeIn':''"
								 style="object-fit: cover;" alt="" @animationend='yixiImg=false'>
							</div>

							<div style="overflow: hidden;height: 120%; " :class="yixiImg?' animate__animated animate__fadeIn':''" class="w-100  bg-blue px-0-5 px-sm-1-5 py-0-5  py-sm-1 text-white pointer  tixi-rsmall d-flex flex-column">

								<div class="font-lmd text my-0-5 my-sm-1   ">{{data.curriculums[isClassActiveIndex].title.split('螺旋递进')[1]}}</div>
								<div class="font  " style="line-height: 2;overflow: auto;height:70%" v-html="data.curriculums[isClassActiveIndex].content"></div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

		<!-- 师资团队 -->
		<div class="subcontent teacher">
			<div class="home-title">
				<div class="font-md text-center ">{{data.configs.teachers_name}}</div>
				<div class="font text-center font-line">{{data.configs.teachers_eng}}</div>
				<div class="font text-center">{{data.configs.teachers_desc}}</div>
			</div>

			<div class="d-block mt-2 d-sm-none">
				<el-carousel :interval="5000" height="2rem" indicator-position="none" arrow="always">
					<el-carousel-item v-for="v in data.teachers">
						<div class="d-flex flex-column align-items-center justify-content-center">
							<img class=" pointer rounded-circle" style="width: 80px ; height: 80px;" :src="$imgURL + v.image" alt="" srcset="" />

							<div class="title pointer font-smd py-0-5">{{ v.title }}</div>

							<div class="desc  text-center pointer font text-secondary col-8" v-html="v.content">
								<!-- {{ v.content }} -->
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="teacher-content d-none d-sm-block mt-2">
				<div class="swiper-button-prev" slot="button-prev" style="outline: none;"></div>
				<div class="swiper-button-next" slot="button-next" style="outline: none;"></div>
				<swiper ref="teacher-Swiper" :options="teacherswiperOptions">
					<swiper-slide v-for="v in data.teachers">
						<div class="item f-0-c-c ">
							<img class="img pointer" :src="$imgURL+v.image" alt="" srcset="" />

							<div class="title pointer">{{v.title}}</div>

							<div class="desc  pointer font text-center text-secondary col-10" v-html="v.content">
								<!-- {{ v.content }} -->
							</div>
						</div>
					</swiper-slide>
				</swiper>


			</div>




		</div>

		<!-- 视频 -->

		<div class="subcontent video-wrap">
			<div class="home-title">
				<div class="font-md text-center ">{{data.configs.videos_name}}</div>
				<div class="font text-center font-line">{{data.configs.videos_eng}}</div>
				<div class="font text-center">{{data.configs.videos_desc}}</div>
			</div>

			<div class="video-content    justify-content-center flex-column flex-lg-row flex">
				<div class="video w-100   d-flex justify-content-center " style="margin-right: 0.5rem;" v-if="data.videos">
					<div>
						<img @click="showVideo(videoIndex)" class="w-100 h-100" :src="$imgURL+data.videos[videoIndex].image" alt="" style="object-fit: cover;">
						<div class="position-relative">
							<div class="position-absolute w-100 h-5 " @click="showVideo(videoIndex)">
							
								<div class="d-flex align-items-center h-100 justify-content-center">
								
									<img src="../assets/img/video.png" class="col-4" alt="">
								</div>
							</div>
						</div>
					</div>
					
				</div>

				<ul class="video-slide  ">
					<li class="f-b-c pointer " style="transition: all 0.3s" v-for="(item,index) in data.videos" :style="{height:index==videoIndex?'205px':'100px'}"
					 :key="index" @click="changViodeIndex(index)" @mouseenter="changViodeIndex(index)">

						<div class="d-flex flex-row justify-content-between w-100">
							<div class="d-flex flex-column   justify-content-center " :style="{height:index==videoIndex?'205px':'100px'}">
								<div class="d-flex flex-row align-items-center">
									<img style="width: 0.24rem;" v-show="index==videoIndex" class="mr-0-5" src="@/assets/img/video-icon.png" alt=""
									 srcset="" />
									<div class=" text-over" :class="index==videoIndex?'font-weight-bold text-blue':''">{{item.title}}</div>
								</div>


								<div class="font mt-1 text-over" style="margin-left: 0.39rem;" v-show="index==videoIndex">{{item.content}}</div>

							</div>

							<div class="d-flex justify-content-center">
								<img class="right-img " style="width: 0.14rem;" src="@/assets/img/right.png" alt="" srcset="" />
							</div>
						</div>

					</li>
				</ul>
			</div>
		</div>



		<!-- 教学环境 -->

		<div class="d-flex justify-content-center mb-1 mb-lg-3 mb-md-3">
			<div class="subcontent container teacher bg-white ">
				<div class="home-title">
					<div class="font-md text-center ">{{data.configs.context_name}}</div>
					<div class="font text-center font-line">{{data.configs.context_eng}}</div>
					<div class="font text-center">{{data.configs.context_desc}}</div>

				</div>

				<div class="d-block mt-2 px-0-5  d-md-none">
					<el-carousel class="rounded-sm " :interval="5000" indicator-position="none" arrow="always">
						<el-carousel-item class=" rounded-sm d-flex justify-content-center  " style="overflow: hidden; " v-for="(item,index)  in data.contexts">
							<div class="h-100  rounded-sm d-flex justify-content-center">
								<div class="  d-flex justify-content-center align-items-center  rounded-sm " style="overflow: hidden; ">
									<!-- <img class="  w-100 huanjing-img rounded-sm" :src="$imgURL+item.image" alt=""> -->
									<el-image class=" w-100  d-flex justify-content-center align-items-center  rounded-sm" fit="contain" :src="$imgURL+item.image" :preview-src-list="[$imgURL+item.image]">
									</el-image>
								</div>
							</div>

						</el-carousel-item>
					</el-carousel>
				</div>

				<div class="huanjing-content px-2  d-none d-md-block mt-2">
					<div class="swiper-button-prev" slot="button-prev" style="outline: none;"></div>
					<div class="swiper-button-next" slot="button-next" style="outline: none;"></div>
					<swiper ref="contex-Swiper "  :options="contexOptions">
						<swiper-slide v-for="(item,index)  in data.contexts">

							<div class="rounded-sm  d-flex  justify-content-center align-items-center" style="overflow: hidden; ">
								<!-- <img class="w-100 h-100 huanjing-img rounded-sm" :src="$imgURL+item.image" alt=""> -->
								<el-image class="w-100   d-flex justify-content-center align-items-center  rounded-sm" fit="contain" :src="$imgURL+item.image" :preview-src-list="[$imgURL+item.image]">
								</el-image>
							</div>
							<!-- </div> -->
						</swiper-slide>
					</swiper>
				</div>
			</div>

		</div>



		<div class="mask" @click="videoShow=false" v-if="videoShow">
			<div class=" d-flex justify-content-center align-items-center h-100 ">

				<video class=" pointer col-lg-6 col-10 " preload="auto" loop autoplay style="height:50%" :poster="$imgURL+data.videos[video].image"
				 :src="$imgURL+data.videos[video].video">
					您的浏览器不支持 video 标签。
				</video>
			</div>
		</div>





		<!-- <footers id="footer" /> -->
	</div>

</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from "vue-awesome-swiper";
	import "swiper/css/swiper.css";

	export default {

		name: "home",
		components: {
			Swiper,
			SwiperSlide

		},

		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper;
			},
		},
		data() {
			return {
				dongTaiDoc: 0,
				yixiImg: true,
				video: '',
				videoShow: false,


				videoIndex: 0,
				msg: '<h2>我是富文本内容</h2>',
				myConfig: {
					// 编辑器不自动被内容撑高
					autoHeightEnabled: false,
					// 初始容器高度
					initialFrameHeight: 240,
					// 初始容器宽度
					initialFrameWidth: "100%",
					UEDITOR_HOME_URL: "/UEditor/",
					serverUrl: ""
				},
				data: {},
				animateTop: 0, //触发动画需要的距离
				swiperOptions: {
					autoplay: {
						delay: 3000, //1秒切换一次
					},
					pagination: {
						el: ".swiper-pagination",
						bulletActiveClass: "my-bullet-active",
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				},
				dynamicOptions: {
					autoplay: {
						delay: 3000, //1秒切换一次
					},
				},

				teacherswiperOptions: {
					slidesPerView: 4,

					slideToClickedSlide: true,
					// centeredSlides: true,
					spaceBetween: 53, //设置距离
					centeredSlidesBounds: true, //当设置了Active Slide居中后，使得第一个和最后一个Slide 始终贴合边缘。

					//spaceBetween : '10%',按container的百分比

					// // 箭头配置
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},


				contexOptions: {
					slidesPerView: 3,

					slideToClickedSlide: true,
					// centeredSlides: true,
					spaceBetween: 53, //设置距离
					centeredSlidesBounds: true, //当设置了Active Slide居中后，使得第一个和最后一个Slide 始终贴合边缘。

					//spaceBetween : '10%',按container的百分比

					// 箭头配置
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},

				// class相关
				classImg: [
					"https://www.up-art.cn/uploads/181023/1-1Q023151S4440.jpg",
					"https://www.up-art.cn/uploads/180928/1-1P92Q50K9526.png",
					"https://www.up-art.cn/uploads/181023/1-1Q023151S4440.jpg",
					"https://www.up-art.cn/uploads/180928/1-1P92Q50K9526.png",
					"https://www.up-art.cn/uploads/181023/1-1Q023151S4440.jpg",
				],
				isClassActiveIndex: 0,
				classList: [{
						id: 1,
						text: "探索艺术（2-3岁）",
					},
					{
						id: 2,
						text: "探索艺术（2-3岁）",
					},
					{
						id: 3,
						text: "探索艺术（2-3岁）",
					},
					{
						id: 4,
						text: "探索艺术（2-3岁）",
					},
					{
						id: 5,
						text: "探索艺术（2-3岁）",
					},
				],
			};
		},
		mounted() {


			this.getData()
			var that = this;
			window.addEventListener('scroll', this.handleScroll)

			// that.isMiddle = document.body.clientWidth <=1024 ? true : false
			that.teacherswiperOptions.slidesPerView = document.body.clientWidth < 920 ? 3 : 4
			window.onresize = () => {
				setTimeout(function() {
					that.teacherswiperOptions.slidesPerView = document.body.clientWidth < 920 ? 3 : 4
					// that.isMiddle = document.body.clientWidth <=1024 ? true : false
				}, 400)

			}
		},
		methods: {
			getNum(e) {
				let num = 0
				if (e == 0) {
					num = 0
				} else if (e == 1) {
					num = 25
				} else if (e == 2) {
					num = 50
				} else if (e == 3) {
					num = 75
				}
				return num
			},
			docChange(e, index) {
				let num = this.getNum(e)
				this.data.dynanics[index].doc = num
			},
			dongTaiChange(e) {
				let num = this.getNum(e)
				this.dongTaiDoc = num
			},

			showVideo(index) {
				this.video = index
				this.videoShow = true
				console.log(index, this.video);
			},
			scroll() {

				document.getElementById('footer').scrollIntoView()
			},
			changViodeIndex(index) {
				this.videoIndex = index
			},
			getData() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "api/index/index",
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						res.data.data.dynanics.map(item => {
							let obj = item
							item.doc = 0
						})
						this.data = res.data.data

					}
				});
			},





			// 移入移出事件
			// 课程部分
			classMousemove(i) {
				this.isClassActiveIndex = i;
				this.yixiImg = true
			},

			classMouseleave(i) {},



			// 事件监听

			handleScroll() { // 实现当滚动到指定位置，触发动画




				// let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度

				// if (this.animateTop < scrollTop ) {
				// 	this.$refs.animate_li[0].classList.add("animate__fadeInUp", "animate-duration1")
				// 	this.$refs.animate_li[1].classList.add("animate__fadeInUp", "animate-duration2")
				// 	this.$refs.animate_li[2].classList.add("animate__fadeInUp", "animate-duration3")


				// 	this.$refs.activeing_h1.classList.add("animate__fadeInUp", "animate-duration3")



				// 	this.$refs.activeing_h2.classList.add("animate__fadeInUp", "animate-duration1")


				// 	this.$refs.activeing_h3.classList.add("animate__fadeInUp", "animate-duration2")
				// }

			},










			navTo(num, id, text, sid, name) {
				sessionStorage.setItem("subtitles", "首页");
				switch (num) {
					case 1:
						this.$router.push({
							name: "newdetail",
							query: {
								id: id
							}
						});
						break;
					case 2:
						sessionStorage.setItem("type", "名牌推荐");
						this.$router.push("activelist");
						break;
					case 3:
						this.$router.push("fylist");
						break;
					case 4:
						this.$router.push("mpshow");
						break;
					case 5:
						sessionStorage.setItem("type", "最新动态");
						sessionStorage.setItem("typename", "最新动态");
						this.$router.push("activelist");
						break;
					case 6:
						this.$router.push("zxlist");
						break;
					case 7:
						var that = this;
						that.codeId = sid; //后台需要的id
						that.codeType = text;
						that.myId = id;
						that.name = name;
						that.codeHeaderORList = "1";
						// if (text == "0") {
						//   if (!sessionStorage.getItem("code0")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "1") {
						//   if (!sessionStorage.getItem("code1")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "2") {
						//   if (!sessionStorage.getItem("code2")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "3") {
						//   if (!sessionStorage.getItem("code3")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "4") {
						//   if (!sessionStorage.getItem("code4")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "5") {
						//   if (!sessionStorage.getItem("code5")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// }

						var obj = {
							id: id,
							name: name,
						};

						sessionStorage.setItem("searchs", JSON.stringify(obj));
						sessionStorage.setItem("type", "名牌展示头部");
						this.$router.push({
							name: "qylist",
							query: {
								id: id
							}
						});
						break;
					case 8:
						var that = this;
						that.codeId = sid; //后台需要的id
						that.codeType = text;
						that.myId = id;
						that.codeHeaderORList = 2;
						// if (text == "0") {
						//   if (!sessionStorage.getItem("code0")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "1") {
						//   if (!sessionStorage.getItem("code1")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "2") {
						//   if (!sessionStorage.getItem("code2")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "3") {
						//   if (!sessionStorage.getItem("code3")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "4") {
						//   if (!sessionStorage.getItem("code4")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// } else if (text == "5") {
						//   if (!sessionStorage.getItem("code5")) {
						//     that.centerDialogVisible = true;
						//     return;
						//   }
						// }
						this.$router.push({
							name: "qydetail",
							query: {
								id: id
							}
						});

						break;
					case 9:
						this.$router.push({
							name: "mpdetail",
							query: {
								id: id
							}
						});
						break;
					case 10:
						sessionStorage.setItem("type", "资讯中心");
						sessionStorage.setItem("typename", text);
						this.$router.push({
							name: "activelist",
							query: {
								id: id
							}
						});
						break;
					case 11:
						this.$router.push({
							name: "information"
						});
						break;
					case 12:
						sessionStorage.setItem("typename", "名牌榜");
						sessionStorage.setItem("type", "名牌榜");
						this.$router.push({
							name: "activelist"
						});
						break;
					case 13:
						this.$router.push({
							name: "qydetail",
							query: {
								id: id
							}
						});
						break;
					case 14:
						sessionStorage.setItem("type", "申报指南");
						sessionStorage.setItem("typename", "申报指南");
						this.$router.push({
							name: "activelist",
							query: {
								id: id
							}
						});
						break;
					case 17:
						sessionStorage.setItem("type", "名牌榜");
						this.$router.push({
							name: "newdetail",
							query: {
								id: id
							}
						});
						break;
					case 18:
						this.$router.push({
							name: "login"
						});
						break;
					case 19:
						this.$router.push({
							name: "fydetail",
							query: {
								id: id
							}
						});
						break;
					case 20:
						this.$router.push("guide");
						break;
				}
			},





			openTo(url) {
				window.open(url);
			},

			zixunFn() {
				//资讯
				var that = this;
				that.api.show(8).then((res) => {
					if (res.data.code == 1) {}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.huanjing-content {
		position: relative;
		width: 100%;
		padding: 0 0.4 !important;
		box-sizing: border-box;
	}
	.h-5 {
		height: 5rem;
		top: -5rem;
	}

	.mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 9999999999;
	}



	.text-blue {
		color: #a2dffc
	}

	.w-100-tixi {
		width: 100%;
	}

	.tixi-nsm1 {
		display: block !important;
	}

	.tixi-sm1 {
		display: none !important;
	}

	.video {
		width: 6.06rem !important;
		height: 5rem !important;
	}

	@media screen and (max-width:920px) {
		.teacher {
			.teacher-content {
				position: relative;
				width: 100%;
				padding: 0 0.8rem !important;
				box-sizing: border-box;
			}
		}

		.video-wrap {
			background: #fff;
			padding: 0.81rem 0;

			.video-content {
				width: 100%;
				margin-top: 0.65rem;

				.video {
					width: 100% !important;
					height: 5rem;
				}
				.video-slide {
					margin-right: 0.5rem !important;
					margin-top: 0.5rem;
					width: auto !important;
					
				}
			}
		}
	}

	@media screen and (max-width:576px) {
		.h-5 {
			height: 3rem;
			top: -3rem;
		}

		.video {
			width: 100% !important;
			height: 3rem !important;
		}

		.swiper-pagination {
			height: 116px !important;
		}




		.tixi-rsmall {
			height: 2.5rem;
		}

		.tixi-samll {
			height: 2rem;
		}

		.tixi-nsm1 {
			display: none !important;
		}

		.tixi-sm1 {
			display: block !important;
		}

		.w-100-tixi {
			width: auto;
		}

		.p-tixi {
			padding: 0.04rem 0.08rem;
		}

		.teacher {
			.teacher-content {
				position: relative;
				width: 100%;
				padding: 0 0.4rem !important;
				box-sizing: border-box;
			}
		}

		.home-title {
			h1 {
				font-size: 0.22rem !important;
				margin-bottom: 0.13rem;
				text-align: center;
			}


		}

		.video-wrap {

			.video-content {



				.video-slide {
					margin: 0.1rem !important;
					width: auto !important;
					height: 3rem !important;

					li {
						font-size: 0.18rem !important;
						padding: 0.2rem 0 !important;

						.flex-top {
							.flex-top-cont {
								.title {
									font-size: 0.18rem !important;
								}
							}
						}

						.right-img {
							width: 0.1rem !important;
						}
					}
				}
			}
		}

	}

	.huanjing-img:hover {
		transform: scale(1.2);
	}

	.huanjing-img:hover {
		transition: all 0.6s;
	}

	p {
		margin-bottom: 0rem !important;
	}



	.img-ii {
		// &:hover .sub-progress {
		// 	width: 100% !important;
		// 	background-color: #fdd23e !important;
		// }

		// &:hover {
		// 	color: #fdd23e !important;
		// }

		.progress {
			// width: 4.5rem;
			height: 0.02rem;

			background: #d8d8d8;

			.sub-progress {
				width: 0.4rem;
				transition: 0.4s all;

				height: 100%;
				background: #000000;
			}
		}
	}







	.swiper {
		width: 100%;
		height: 100%;
	}

	.activeing-wrap {
		// padding: 0.63rem 2.97rem 1.08rem 2.97rem;
		background: #f2f2f2;

		// 长脸叔叔动态
		.activeimg-cont {
			margin-top: 0.56rem;
			width: 100%;
			// text-align: center;

			.img-i {
				// width: 4.5rem;

				&:hover .sub-progress {
					width: 100% !important;
					background-color: #fdd23e !important;
				}

				&:hover {
					color: #fdd23e !important;
				}

				.img {
					width: 100%;
					// height: 4.5rem;
					margin-bottom: 0.32rem;
				}

				.title {
					// font-size: 0.15rem;
					margin-bottom: 0.14rem;
					letter-spacing: 0.01rem;
				}

				.progress {
					// width: 4.5rem;
					height: 0.02rem;

					background: #d8d8d8;

					.sub-progress {
						width: 0.4rem;
						transition: 0.4s all;

						height: 100%;
						background: #000000;
					}
				}
			}
		}
	}

	// 课程体系

	.class {
		// padding: 1.21rem 2.9rem 2.37rem 2.9rem;
		background: url(../assets/img/home-bg1.png) no-repeat;
		background-size: cover;

		.class-content {
			margin-top: 0.87rem;

			.left {
				li {
					width: 2.5rem;
					height: 0.8rem;
					font-size: 0.2rem;
					text-align: center;
					line-height: 0.8rem;
					background: #ffe000;

					// margin-bottom: 0.74rem;
					margin: 0.5rem 0;

					&:last-child(1) {
						margin-bottom: 0;
					}

					&.class-active {
						background: #a0a0a0 !important;
						color: #fff !important;
					}
				}
			}

			.right {
				.img-wrap {
					width: 8rem;
					height: 6rem;
					//background: #d8d8d8;
					position: relative;

					.opacity-1 {
						opacity: 1 !important;
					}

					.img {
						width: 100%;
						position: absolute;
						height: 100%;
						opacity: 0;
						transition: 1s all;
					}
				}

				.text {
					transition: 1s all;
					// opacity: 0;
				}

				.desc {

					width: 4rem;
					height: 7.5rem;

					background: #a2dffc;
				}
			}
		}
	}

	//

	.teacher {
		background: #f2f2f2;

		padding: 0.64rem 0;
		box-sizing: border-box;

		.teacher-content {
			position: relative;

			width: 100%;
			padding: 0 2.11rem;
			box-sizing: border-box;

			.item {
				width: 100%;

				.img {
					width: 1.36rem;
					height: 1.36rem;
					background: #d8d8d8;
					margin-bottom: 0.34rem;
					border-radius: 50%;
				}

				.title {
					font-size: 0.16rem;
					text-align: center;
					margin-bottom: 0.27rem;
				}

				.desc {
					font-size: 0.12rem;

					color: #999999;
				}
			}
		}
	}

	// 视频

	.video-wrap {
		background: #fff;
		padding: 0.81rem 0;

		.video-content {
			width: 100%;
			margin-top: 0.65rem;

			.video {
				width: 6.06rem;
				height: 5rem;
				// background: #d8d8d8;
				// margin-right: 1rem;
			}

			.video-slide {
				margin-right: 1rem;
				margin-left: 0.5rem;
				width: 5.06rem;
				height: 5rem;
				background: #ffffff;
				box-shadow: 0px 0px 0.24rem 0.03rem rgba(0, 0, 0, 0.14);
				padding: 0 0.2rem;
				box-sizing: border-box;

				li {
					padding: 0.35rem 0;
					box-sizing: border-box;
					border-bottom: 1px solid #f2f2f2;
					font-size: 0.26rem;

					.right-img {
						width: 0.14rem;
						margin-left: 0.06rem;
					}

					color: #999999;

					&:last-child(1) {
						margin-bottom: 0;
					}

					// 选中的样式

					.flex-top {
						.right-img {
							width: 0.32rem;
							height: 0.32rem;
							margin-right: 0.2rem;
						}

						.flex-top-cont {
							.title {
								font-size: 0.26rem;

								color: #a2dffc;
							}

							.desc {
								color: #999999;
								margin-top: 0.16rem;
								font-size: 0.14rem;
							}
						}
					}
				}
			}
		}
	}

	.env-wrap {
		background: #fff;
		// padding: 0.475rem 1.68rem 0.95rem;

		.env-content {
			width: 100%;
			margin-top: 0.39rem;

			li {
				width: 5rem;
				// height: 3rem;
				// background: #d8d8d8;
				border-radius: 0.12rem;
				// border: 1px solid #979797;
			}
		}
	}

	// 公共部分
	.subcontent {
		width: 100%;
		box-sizing: border-box;
	}

	.home-title {
		h1 {
			font-size: 0.3rem;
			margin-bottom: 0.13rem;
			text-align: center;
		}

		h2 {
			font-size: 0.14rem;
			text-align: center;

			margin-bottom: 0.13rem;
		}

		h3 {
			font-size: 0.15rem;
			text-align: center;
		}
	}


	// 动画时长

	.animate-duration1 {
		--animate-duration: 0.5s;

	}

	.animate-duration2 {
		--animate-duration: 1s;

	}

	.animate-duration3 {
		--animate-duration: 1.5s;

	}
</style>
