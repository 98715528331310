<template>
	<div class="pt-2 pb-2 pb-lg-4 pb-md-4 pb-sm-4  px-md-4  px-1 footer-box container-fluid">
		<div class="d-flex flex-lg-row flex-sm-column flex-column align-items-end">
			<div class="col-lg-6   col-md-12 ">
				<div class="font top-text uppercase">UNCLE LONG FACE ART</div>
				<div class=" mt-1 uppercase font-md" style="letter-spacing: 0.1rem;">
					contact us
				</div>
				<div class="d-flex align-items-end flex-row">
					<div class="font-lg uppercase " style="letter-spacing: 0.1rem;">online <span class="font-md  " style="letter-spacing: 0.01rem;">联系我们</span></div>
					<!-- <div class="font-smd  ml-1">联系我们</div> -->
				</div>
				<div class=" mt-1 mt-sm-2 font d-flex flex-row align-items-center">
					<div class="d-flex align-items-center mr-1">
						<img src="../assets/img/loc.png" class="icon" alt="">
					</div>
					<div>{{data.address}}</div>
				</div>
				<div class=" mt-sm-1 mt-0-5 font d-flex flex-row align-items-center">
					<div class="d-flex align-items-center mr-1">
						<img src="../assets/img/phone.png" class="icon" alt="">
					</div>
					<div>加盟热线 : {{data.tel}}</div>
				</div>

				<div class="d-flex flex-row mt-sm-1 mt-0-5 " v-if="data.code_images">
					<div>
						<img :src="$imgURL+data.code_images[0]" class="qr" alt="">
					</div>
					<div class="ml-2">
						<img :src="$imgURL+data.code_images[1]" class="qr" alt="">
					</div>
				</div>
			</div>

			<div class="col-lg-6  d-flex justify-content-md-end p-0  col-md-12 mt-2 ">

				<div class="d-flex flex-column font    px-0">
					<div style="letter-spacing: 0.3rem;" class="font-smd">在线申请</div>
					<div class=" ">
						<div class=" mt-sm-1 mt-0-5  d-flex flex-row ">
							<div style="white-space: nowrap;">姓名：</div>
							<input type="text " class="input-text" v-model="form.name">
						</div>
						<div class="mt-sm-1 mt-0-5  d-flex flex-row ">
							<div style="white-space: nowrap;">电话：</div>
							<input type="text" class="input-text" v-model="form.tel">
						</div>
						<div class=" mt-sm-1 mt-0-5  d-flex flex-row ">
							<div style="white-space: nowrap;">加盟区域：</div>
							<input type="text" class="shengshi" v-model="form.province">
							<div style="white-space: nowrap;">省</div>
							<input type="text" class="shengshi" v-model="form.city">
							<div style="white-space: nowrap;">市</div>
						</div>
						<div class="w-100 d-flex justify-content-end mt-1 align-items-end ">
							<div class=" font text-center border-white col-3  " @click="confirm">提交</div>
						</div>
					</div>


					<div class=" mt-2">
						{{data.copyright}}
					</div>
					<div class=" mt-0-5">{{data.beian}}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				data: {},
				form: {
					name: '',
					tel: '',
					province: '',
					city: ''
				},
				isSmall:'',
			};
		},
		mounted() {
			this.getFooter()
			let that = this
			that.isSmall = document.body.clientWidth < 567 ? true : false
			window.onresize = () => {
				setTimeout(function() {
					that.isSmall = document.body.clientWidth < 567 ? true : false
				}, 400)
			
			}
		},
		methods: {
			telFun(){
			     var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
			    if (!myreg.test(this.form.tel)) {
			      return false;
			    } else {
			      return true;
			    }
			},
			error(msg) {
				if(this.isSmall){
					alert(msg)
				}else{
					this.$message({
						showClose: true,
						message: msg,
						type: 'error'
					});
				}
				
			},
			confirm() {
				if (this.form.name == '') return this.error('姓名不能为空')
				if (this.form.tel == '') return this.error('电话不能为空')
				if (!this.telFun()) return this.error('手机号错误')
				if (this.form.province == '') return this.error('省不能为空')
				if (this.form.city == '') return this.error('市不能为空')
				this.api.joinusBottom(this.form).then((res) => {
					if (res.data.code == 1) {
						
						
						
						if(this.isSmall){
							alert('申请成功')
						}else{
							this.$message({
								showClose: true,
								message: '申请成功',
								type: 'success'
							});
						}
					}
				});
				
			},
			getFooter() {
				this.$axios({
					method: "post",
					url: this.$apiURL + "/api/index/common"
				}).then((res) => {
					if (res.code < 1) {
						console.log("网络错误");
						return false;
					}

					if (res.data.data) {
						console.log(res.data.data);
						this.data = res.data.data
					}
				});
			}
		},
	};
</script>
<style lang="scss">
	.el-message .el-icon-error {
		font-size: 15px;
	}
	.el-message .el-icon-success{
		font-size: 15px;
	}

	.input-text {
		border-bottom: 0.01rem solid #FFFFFF;
		width: 4rem;
	}

	.top-text {
		letter-spacing: 0.2rem
	}

	.shengshi {
		border-bottom: 0.01rem solid #FFFFFF;
		width: 1.6rem;
	}

	@media (max-width: 576px) {
		.input-text {
			border-bottom: 0.01rem solid #FFFFFF;
			width: 2.5rem !important;
		}

		.shengshi {
			border-bottom: 0.01rem solid #FFFFFF;
			width: 1rem;
		}

		.top-text {
			letter-spacing: 0.08rem
		}

		.qr {
			width: 1rem !important;
			height: 1rem !important;
		}
	}

	.qr {
		width: 1.5rem;
		height: 1.5rem;
	}

	.icon {
		width: 0.2rem;
		height: 0.2rem;
	}

	.uppercase {
		text-transform: Uppercase
	}

	.border-white {
		border: 0.01rem solid #FFFFFF;
		padding: 0.08rem 0;
	}

	.footer-box {
		padding: 0.6rem 0 1.34rem 1.26rem;
		box-sizing: border-box;
		background: url(../assets/img/app-bottom.png) no-repeat;
		background-size: cover;
		color: #fff;

		h5 {
			font-size: 0.14rem;

			letter-spacing: 0.23rem;
		}

		h4 {
			margin-top: 0.44rem;

			font-size: 0.24rem;
			letter-spacing: 0.05rem;
		}

		.footer-left {
			.t1 {
				margin-top: 0.07rem;

				h3 {
					font-size: 0.36rem;
					letter-spacing: 0.1rem;
					margin-right: 0.26rem;
				}

				span {
					font-size: 0.24rem;
				}
			}

			.t2 {
				margin-top: 0.79rem;

				.img {
					width: 0.26rem;
					height: 0.26rem;
					margin-right: 0.23rem;
				}

				span {
					font-size: 0.18rem;
				}
			}

			.erweima-wrap {
				margin-top: 0.51rem;

				.img {
					width: 1.08rem;
					height: 1.08rem;

					&:first-child {
						margin-right: 0.26rem;
					}
				}
			}
		}




		.footer-right {

			margin-right: 2.59rem;
			margin-top: 0.07rem;

			.title {
				font-size: 0.18rem;
			}

			.btn {
				width: 1.02rem;
				height: 0.38rem;
				border: 1px solid #CCEEFF;
				font-size: 0.15rem;
				float: right;
			}

			.record-wrap {
				// margin-top: 0.54rem;
				font-size: 0.13rem;
				width: 3.99rem;
			}

			.form-wrap {
				margin-top: 0.3rem;
				font-size: 0.15rem;

				.item {
					margin-bottom: 0.47rem;

					&:last-child(1) {
						margin-bottom: 0.39rem;

					}

					.p-15 {
						padding: 0 0.15rem;
					}

					.input {
						border-bottom: 2px solid #CCEEFF;
						width: 2.92rem;
						height: 0.22rem;
					}
				}





			}
		}





	}

	@media screen and (max-width: 768px) {
		.footer {
			line-height: 1.5;

			.text2 {
				display: none;
			}

			span {
				display: block;
			}
		}
	}
</style>
