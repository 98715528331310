import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    redirect: {
      name: "Home",
    },
    component: () => import("../views/layout.vue"),
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/recommend",
        name: "recommend",
        component: () => import("../views/Recommend.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/News.vue"),
      },
      {
        path: "/join",
        name: "join",
        component: () => import("../views/Join.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/Detail.vue"),
      },
      {
        path: "/news_detail",
        name: "News_detail",
        component: () => import("../views/News_detail.vue"),
      },
    ],
  },
  {
    path: "/aa",
    name: "aa",
    component: () => import("../views/aa.vue"),
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("../views/operation.vue"),
  },
  { 
    path: "/institute",
    name: "institute",
    component: () => import("../views/institute.vue"),
  },
  {
    path: "/joint",
    name: "joint",
    component: () => import("../views/join-title.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/activity.vue"),
  },
  {
    path: "/campus",
    name: "campus",
    component: () => import("../views/campus.vue"),
  },
  {
    path: "/diary",
    name: "diary",
    component: () => import("../views/diary.vue"),
  },
  


  // {
  // 	path: '/home',
  // 	name: 'Home',
  // 	component: Home
  // },
  // {
  // 	path: '/recommend',
  // 	name: 'recommend',
  // 	// route level code-splitting
  // 	// this generates a separate chunk (about.[hash].js) for this route
  // 	// which is lazy-loaded when the route is visited.
  // 	component: () => import( /* webpackChunkName: "about" */ '../views/Recommend.vue')
  // },
  // {
  // 	path: '/news',
  // 	name: 'news',
  // 	component: () => import('../views/News.vue')
  // },

  // {
  // 	path: '/about',
  // 	name: 'about',
  // 	component: () => import('../views/About.vue')
  // },

  // {
  // 	path: '/join',
  // 	name: 'join',
  // 	component: () => import('../views/Join.vue')
  // },
  // {
  // 	path: '/detail',
  // 	name: 'detail',
  // 	component: () => import('../views/Detail.vue')
  // },
  // {
  // 	path: '/news_detail',
  // 	name: 'News_detail',
  // 	component: () => import('../views/News_detail.vue')
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
