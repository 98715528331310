<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>


<script>
export default {
	provide() {
		return {
			reload: this.reload
		}
	},
	data() {
		return {
			isRouterAlive: true
		}
	},
	mounted() {
		this.remSize();
		this.init()
	},
	methods: {
		reload() {
			this.isRouterAlive = false
			this.$nextTick(function () {
				this.isRouterAlive = true
			})
		}, remSize() {
			// 获取移动设备的宽度 
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			// 设置最大宽度 (需要限制宽度时添加)
			if (deviceWidth >= 1920) {
				deviceWidth = 1920
			}
			// 设置最小宽度 (需要限制宽度时添加)
			if (deviceWidth <= 320) {
				deviceWidth = 320
			}
			document.documentElement.style.fontSize = (deviceWidth / 19.2) + 'px'
			// 在width=375px时 10px = 0.1rem 在不限制宽度时width:100%; 等同于 width:7.5rem;
			document.querySelector('body').style.fontSize = 0.32 + 'rem'
			console.log("ddddddd");
		},
		init() {
			window.onresize = () => {
				console.log("窗口变化");
				this.remSize()
			}
		}
	},

}
</script>

<style lang="scss">
@media screen and (max-width:576px) {
	#app {
		--color: #FDD23E;
		color: #333;

		//home.vue swiper分页样式
		.swiper-container .swiper-pagination-bullet {
			width: 0.05rem !important;
			height: 0.05rem !important;
			background: #D8D8D8;
		}

		.swiper-container .my-bullet-active {
			opacity: 1;
			width: 0.2rem !important;
			height: 0.05rem !important;
			background: #FFE000;
			border-radius: 0.08rem;
		}
	}
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 1 !important;
	cursor: auto !important;
	pointer-events: auto !important;
}

#app {
	--color: #FDD23E;
	color: #333;

	//home.vue swiper分页样式
	.swiper-container .swiper-pagination-bullet {
		width: 0.1rem;
		height: 0.1rem;
		background: #D8D8D8;
	}

	.swiper-container .my-bullet-active {
		opacity: 1;
		width: 0.3rem;
		height: 0.1rem;
		background: #FFE000;
		border-radius: 0.08rem;
	}
}

.swiper-button-prev,
.swiper-button-next {
	color: #999999 !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
	outline: none !important;
	border-color: transparent !important; // 若是本身具有 border 请删除此属性

	box-shadow: none !important;
	font-size: 0.3rem !important;

}
</style>
