import Vue from 'vue'
import App from './App.vue'
import router from './router'


import 'u-reset.css'
import './assets/css/common.scss'
import './assets/css/bootstrap.css'




//elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);





// 导航栏
import  headers from '@/components/headers.vue'
import  footers from '@/components/footers.vue'
Vue.component('footers',footers)
Vue.component('headers',headers)
// 请求
import api from '@/api'
Vue.prototype.api = api;
// Vue.prototype.url = 'http://admin.js-mp.com/'; //图片地址

import  axios from 'axios';

Vue.prototype.$imgURL = 'http://longface-cms.oss-cn-shanghai.aliyuncs.com'; //图片地址
Vue.prototype.$apiURL = 'http://www.changlianshushu.cn/'
// Vue.prototype.$apiURL = 'http://longface-cms.pzhkj.cn/'
Vue.prototype.$axios = axios;








// 动画
import animated from 'animate.css' 
Vue.use(animated)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
   data:{
      eventHub:new Vue()
    },
}).$mount('#app')
