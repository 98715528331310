import axios from "axios"   //设置基础路径
import qs from 'qs'
const api = axios.create({
    baseURL: 'http://www.changlianshushu.cn/'
    // baseURL: 'http://longface-cms.pzhkj.cn/'
});



export default{


// 申请加入

joinus(data){
        
    // 最新动态
    return api.post('/api/join_us/joinus', qs.stringify(data))
},
joinusBottom(data){
        
    // 最新动态
    return api.post('/api/index/addMessage', qs.stringify(data))
},


// 获取配置信息


config(key){
    // 最新动态
    return api.get('/api/config/config?key='+key)
},






    banner(){
        return api.post('/api/index/getBannerList')
    },
    news(data,page=1){
        
        // 最新动态
        return api.post('/api/articles/news?page='+page, qs.stringify({ category_id:data}))
    },
    newsDetail(data,page=1){
        // 最新动态
        return api.post('/api/articles/detail?page='+page, qs.stringify({ article_id:data}))
    },
    showList(page=1){
        // 名牌列表
        return api.post('/api/enterprises/enterpriselist?page='+page)
    },
    showDetail(data){
        // 名牌详情
        return api.post('/api/Enterprises/getMpDetail', qs.stringify({enterprise_id:data}))
    },
    show(data,page=1){
       //名牌头部的推荐部分， 咨询中心 / 申报指南
        return api.post('/api/articles/fashionAndConsult?page='+page, qs.stringify({category_id:data}))
    },
    showFnContent(){
        return api.post('api/Enterprises/getIndustry')

    },
    show2(data,data2,page=1){
        //名牌头部的推荐部分， 咨询中心 / 申报指南
         return api.post('/api/articles/fashionAndConsult?page='+page, qs.stringify({category_id:data,se_category_id:data2}))
     },
    qyList(data,page=1){
        // 企业列表,名牌展示头部点击后的页面接口
        return api.post('/api/enterprises/enterpriselist?page='+page, qs.stringify({industry:data}))
    },
    qyDetail(data){
        // 企业详情
        return api.post('/api/enterprises/detail', qs.stringify({enterprise_id:data}))
    },
    zxList(data,page){
        // 咨询中心头部和【业界动态】,
        return api.post('/api/articles/fashionAndConsult?page='+page, qs.stringify({se_category_id:data,category_id: 8}))

    },
    mpbList(page=1){
        // 名牌榜列表
        return api.post('/api/brands/index?page='+page)

    },
    mpbDetail(data){
        // 名牌榜详情
        return api.post('/api/brands/detail', qs.stringify({brand_id:data}))

    },  
    mptj(page=1){
   // 名牌推荐
   return api.post('/api/enterprises/fashionRecommend?page='+page)
    },
    zxList2(page=1){
        // 最新加入列表
        return api.post('/api/enterprises/latestAdd?page='+page)
    },
    fyList(page=1){
        // 风云人物列表
        return api.post('/api/Influentials/Influentiallist?page='+page)
    },
    hyList(){
        // 行业列表
        return api.post('/api/enterprises/getIndustry')

    },
    login(phone,password){
        return api.post('/api/customers/login',qs.stringify({nickname:phone,password:password}))
    },
    ggList(page=1){
        // 名牌公告列表
        return api.post('api/Enterprises_notice/noticelist?page='+page)
    },
    mpggdetail(data){
        // 名牌公告详情
        return api.post('/api/Enterprises_notice//detail',qs.stringify({enterprise_notice_id:data}))
    },
    search(industry,key,page=1){
        // 搜索
        return api.post('/api/enterprises/enterpriselist?page='+page,qs.stringify({industry:industry,key:key}))
    },
    search2(key,page=1){
        // 搜索
        return api.post('/api/articles/search?page='+page,qs.stringify({key:key}))
    },
    fyDetail(id){
        return api.post('/api/Influentials/detail',qs.stringify({influential_id:id}))
    },
    cate(category_id,page=1){
        return api.post('/api/articles/getArticleListByCateId?page='+page,qs.stringify({category_id:category_id}))
    },
    ziSearchList(){
        return api.post('/api/articles/fashionAndConsult',qs.stringify({category_id:8}))
    },
    ziSearch(id,text){
        return api.post('/api/articles/getArticleListByCateId',qs.stringify({category_id:id,key:text}))
    },
    homeItem(){
        return api.post('api/index/getMenuList')
    },
    codeFn(id){
        return api.post('/api/index/getCode',qs.stringify({category_id:id}))

    },
    getInfo(id){
        return api.post('/api/Enterprises/detailCode',qs.stringify({key:id}))
    },
}


    // get (page,limit){
    //     return http.get(url+`?page=${page}&limit=${limit}`)
    // },
