<template>
	<div class="head ">

		<div class="d-flex flex-row justify-content-between px-0-5 d-block d-sm-none w-100">
			<div class="d-flex justify-content-center " style="padding: 0.08rem 0;height: 0.7rem;">
				<img class="headl w-100" @click="mpShowFn(0,-1)" :src="$imgURL+logo" />
			</div>
			<div class="pl-1">
				<div class="record   d-none d-sm-block f-c-c text-center  p-0-5 font-sm d-inline float-right">
					<div style="white-space: nowrap;">全国咨询热线：</div>
					<div class="font-weight-bold " style="margin-bottom: 2px;">{{tel}}</div>
					<!-- <div style="white-space: nowrap;">国家商务特许加盟备案</div> -->
					<div>{{join_code}}</div>
				</div>
			</div>

		</div>

		<div class="headl d-none d-sm-block">
			<div class="headl1 d-flex justify-content-center">

				<img class="headl" @click="mpShowFn(0,-1)" :src="$imgURL+logo" />

			</div>

		</div>
		<div class="headr d-none d-sm-block">
			<div class="record   d-none d-sm-block f-c-c text-center    font-sm d-inline float-right"
				style="line-height: 1.4;padding: 0.1rem 0.38rem;">
				<div>全国咨询热线：</div>
				<div class="font-weight-bold " style="margin-bottom: 4px;">{{tel}}</div>
				<!-- <div>国家商务特许加盟备案</div> -->
				<div>{{join_code}}</div>
				<!-- <span style="white-space: nowrap;">国家商务特许加盟备案</span><br />
				{{join_code}} -->
			</div>
		</div>

		<ul class="headm nav ">
			<li class="headm col-2 p-0 " :id="index" v-for="(item,index) in headerText" :key="index"
				@click="mpShowFn(index,0)" @mouseleave="mouseleave(index)" @mousemove="mousemove(index)">
				<div class="headms pointer" :class="index==headIndex?'bg-default':''">
					<div class="headmsk"></div>
					<div class="headmst">{{item.text}}</div>
					<div class="headmsd">
						<img class="img" v-if="index==0" src="@/assets/img/home-nav1.png" alt="" />
						<img class="img" v-if="index==1" src="@/assets/img/home-nav2.png" alt="" />
						<img class="img" v-if="index==2" src="@/assets/img/home-nav3.png" alt="" />
						<img class="img" v-if="index==3" src="@/assets/img/home-nav4.png" alt="" />
						<img class="img" v-if="index==4" src="@/assets/img/home-nav5.png" alt="" />
						<img class="img" v-if="index==5" src="@/assets/img/home-nav6.png" alt="" />
					</div>
				</div>
				<div class="abs" ref="navabs">
					<div class="abs-li hidden1 pointer font-sm" @click.stop="mpShowFn(index,ii)"
						v-for="(vv, ii) in item.children" :key="ii">
						{{vv.text}}
					</div>
				</div>

			</li>

		</ul>



	</div>
</template>

<script>
export default {
	inject: ['reload'],
	props: {
		num: {
			type: String,
			default: "",
		},
		isscroll: {
			type: String,
			default: "",
		},
		// headIndex: {
		// 	type: Number,
		// 	default: 0
		// }
	},

	data() {
		return {
			headIndex: 0,
			logo: '',
			join_code: '',
			tel: '',

			isvip: sessionStorage.getItem("login"),
			input_value: "",
			lengths: "4", //路径分割后有几个
			headerItem: sessionStorage.getItem("titles"),
			headerText: [{
				id: 0,
				text: "首页",
				img: '@/assets/img/home-nav1.png',
				children: [
					// { id: 0, text: "品牌介绍" },
					// { id: 1, text: "教学理念" },
					// { id: 2, text: "师资团队" },
				],
			},
			{
				id: 1,
				text: "关于我们",
				img: '@/assets/img/home-nav1.png',
				children: [{
					id: 0,
					text: "品牌介绍",
				},
				{
					id: 1,
					text: "教学理念"
				},
				{
					id: 2,
					text: "师资团队"
				},
				],
			},
			{
				id: 2,
				text: "课程介绍",
				children: [{
					id: 0,
					text: "课程描述"
				},
				{
					id: 1,
					text: "课程体系"
				},
				{
					id: 2,
					text: "云端课堂"
				},
				],
			},

			{
				id: 3,
				text: "新闻动态",
				children: [{
					id: 0,
					text: "近期活动"
				},
				{
					id: 1,
					text: "新闻资讯"
				},
				],
			},
			{
				id: 4,
				text: "加盟合作",
				children: [{
					id: 0,
					text: "流程"
				},
				{
					id: 1,
					text: "支持"
				},
				{
					id: 2,
					text: "校区展示"
				},
				],
			},

			{
				id: 5,
				text: "联系我们",
				children: [
					// 	{
					// 	id: 0,
					// 	text: "品牌介绍"
					// },
				]
			},
			],

			isscroll1: false,
		};
	},
	watch: {
		isscroll1() {
			setTimeout(() => {
				var dom = document.querySelector(".aa");
				dom.scrollTo(200, 0);
			}, 1);
		},
		isscroll: {
			handler(newName, oldName) {
				if (this.isscroll == "1") {
					this.isscroll1 = true;
				}
			},
			immediate: true,
		},
	},
	mounted() {
		// this.lengths = location.href.split("/").length - 1;
		// if (!sessionStorage.getItem("headerText")) {
		//   this.getHomeItem();
		// } else {
		//   this.headerText = JSON.parse(sessionStorage.getItem("headerText"));
		// }

		this.getHeader()
		console.log(sessionStorage.getItem('headIndex'));
		this.headIndex = sessionStorage.getItem('headIndex') || 0
		console.log(this.headIndex);
	},
	methods: {
		getHeader() {
			this.$axios({
				method: "post",
				url: this.$apiURL + "/api/index/common"
			}).then((res) => {
				if (res.code < 1) {
					console.log("网络错误");
					return false;
				}

				if (res.data.data) {
					console.log(res.data.data);
					this.logo = res.data.data.logo
					this.join_code = res.data.data.join_code
					this.tel = res.data.data.tel
				}
			});

		},
		mouseleave(i) {
			this.$refs.navabs[i].style.height = 0
		},
		mousemove(i) {
			this.$refs.navabs[i].style.height = this.headerText[i].children.length * 0.5 + 'rem'
		},
		getHomeItem() {
			var that = this;
			// 名牌列表
			that.api
				.homeItem()
				.then((res) => {
					if (res.data.code == 1) {
						sessionStorage.setItem("headerText", JSON.stringify(res.data.data));
						this.headerText = res.data.data;
					} else {
						that.$Message({
							showClose: true,
							message: res.data.msg,
							type: "error",
						});
					}
				})
				.catch((err) => {
					that.$Message({
						showClose: true,
						message: JSON.stringify(err),
						type: "error",
					});
				});
		},
		navTo() {
			var that = this;
			if (that.input_value == "") {
				that.$Message({
					showClose: true,
					message: "请输入搜索内容",
					type: "warning",
				});
				return;
			}

			if (location.href.indexOf("qydetail") > 0) {
				this.$emit("searchevent", that.input_value);
			} else {
				sessionStorage.setItem("type", "站内搜索");

				this.getInfo();
			}
			that.input_value = "";
		},
		loginFn() {
			this.isshow = false;
			this.$router.push("login");
		},

		mpShowFn(i, ii = 0) {
			this.headIndex = i
			sessionStorage.setItem('headIndex', i)
			if (i == '0') {
				this.$router.push('/'); // 判断是否需要跳首页
			} else if (i == 1) {

				this.$router.push({
					name: "about",
					query: {
						type: ii
					}
				});
			} else if (i == 2) {
				// this.$router.push("/recommend");
				// if (ii == -1) {
				// 	this.$router.push({
				// 		name: "recommend",
				// 		query: {
				// 			type: 0
				// 		}
				// 	});
				// } else {

				this.$router.push({
					name: "recommend",
					query: {
						type: ii
					}
				});
				// }
				// this.reload()
			} else if (i == 3) {
				// this.$router.push("/news");
				// if (ii == -1) {
				// 	this.$router.push({
				// 		name: "news",
				// 		query: {
				// 			type: 0
				// 		}
				// 	});
				// } else {
				this.$router.push({
					name: "news",
					query: {
						type: ii
					}
				});
				// }
				// this.reload()
			} else if (i == 4) {
				// if (ii == -1) {
				// 	this.$router.push({
				// 		name: "join",
				// 		query: {
				// 			type: -1
				// 		}
				// 	});
				// } else {
				this.$router.push({
					name: "join",
					query: {
						type: ii
					}
				});
				// }
			} else if (i == 5) {
				this.$emit('scroll')
				// document.getElementById('footer').scrollIntoView()
				// this.$router.push("/recommend");
			} else if (i == 6) {
				window.open("http://new.js-mp.com/#/");
			}
			this.mouseleave(i)


		},
	},
};
</script>
<style lang="scss">
.bg-default {
	background: #fed23e;
}

@media screen and (max-width:576px) {

	.nav {
		li {
			.abs {
				.abs-li {
					font-size: 0.12rem !important;
				}
			}
		}
	}
}

.nav {
	li {
		height: 1.2rem;
		font-size: 0.2rem;
		text-align: center;
		position: relative;

		&:hover {
			background: #fed23e;
			transition: all 0.3s ease-out 0s
		}

		&:hover .img {
			opacity: 1 !important;
		}

		&:hover .abs {
			display: block;
			height: 0;
		}

		.top {
			height: 100%;
			width: 100%;

			.replace {
				width: 100%;
				height: 50%;
			}

			.img {
				margin-bottom: 0.1rem;
				height: 0.20rem;
				opacity: 0;
				object-fit: cover;
			}
		}

		.abs {
			height: 0;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			overflow: hidden;

			transition: 0.4s height;
			background: #fed23e;
			transform: translateY(100%);

			.abs-li {
				width: 100%;
				height: 0.5rem;
				border-top: 1px solid #ebca0e;
				font-size: 0.15rem;

				line-height: 0.5rem;
				text-align: center;
			}
		}
	}
}
</style>
<style scoped>
.img {
	/* margin-bottom: 0.1rem; */
	height: 0.25rem;
	/* opacity: 0; */
	object-fit: cover;
}


div.record {
	background: #fed23e;
	border-radius: 0px 0px 0.24rem 0.24rem;
	font-size: 0.18rem;
	line-height: 1.2;
}

div.head {
	width: 100%;
	height: 160px;
}

div.head div.headl {
	width: 522px;
	height: 160px;
	float: left;
	margin-left: 28px;
}

div.head div.headl div.headl1 {
	width: 100%;
	height: 160px;
}

div.head div.headl div.headl1 img.headl {
	width: 100%;
}

div.head div.headr {
	width: 247px;
	height: 160px;
	float: right;
	margin-right: 62px;
	position: relative;
}

div.head div.headr a.headrsanliuling {
	width: 60px;
	height: 30px;
	display: block;
	position: absolute;
	top: 34px;
	left: 0;
}

div.head div.headr a.headryuyue {
	width: 83px;
	height: 30px;
	display: block;
	position: absolute;
	top: 34px;
	right: 88px;
}

div.head div.headr a.headrshenqing {
	width: 83px;
	height: 30px;
	display: block;
	position: absolute;
	top: 34px;
	right: 0;
}

div.head ul.headm {
	height: 145px;
	float: right;
	margin-right: 28px;
}

div.head ul.headm li.headm {
	width: 120px;
	height: 145px;
	float: left;
	position: relative;
}

div.head ul.headm li.headm div.headms {
	width: 120px;
	height: 145px;
}

div.head ul.headm li.headm div.headms div.headmsk {
	width: 120px;
	height: 65px;
}

div.head ul.headm li.headm div.headms div.headmst {
	width: 120px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	font-size: 18px;
	color: #1a1b1b;
	font-weight: bold;
}

div.head ul.headm li.headm div.headms div.headmsd {
	width: 120px;
	height: 36px;
	text-align: center;
	line-height: 36px;
	font-size: 24px;
	color: #ffffff;
	text-transform: uppercase;
	font-family: 'Conv_ARGONPERSONAL-REGULAR';
}

div.head ul.headm li.headm:hover {
	background: #fed23e;
}

div.headmx {
	width: 120px;
	height: 0px;
	overflow: hidden;
	position: absolute;
	top: 145px;
	left: 0;
	z-index: 999;
	background: #fed23e;
}

div.headmx div.headmxe {
	width: 120px;
	height: 40px;
	margin: auto;
	border-top: 1px solid #ebca0e;
	text-align: center;
}

div.headmx div.headmxe a.headmxe {
	line-height: 40px;
	font-size: 12px;
	color: #5f5623;
	font-weight: bold;
}

@media screen and (max-width:768px) {


	div.head div.headl {
		width: 200px !important;

		margin-left: 10px;
		height: 70px;
	}

	div.head {
		width: 100%;
		height: 120px;
	}

	div.head div.headl div.headl1 {
		height: 70px;
	}

	div.head div.headr {
		display: block;
		height: 70px;
		margin-right: 0.1rem;
		width: auto;
	}

	div.record {
		font-size: 0.1rem !important;
		padding: 0.05rem 0.1rem;
	}

	div.head ul.headm li.headm {
		width: 60px;
	}

	div.head ul.headm li.headm div.headms div.headmst {
		width: 60px;
		font-size: 0.12rem;
	}

	div.head ul.headm li.headm {
		width: 60px;
		height: 60px;
	}

	div.head ul.headm li.headm div.headms {
		width: 60px;
		height: 60px;
	}

	div.head ul.headm {
		height: 60px;
		margin-right: 2px;
	}

	div.head ul.headm li.headm div.headms div.headmsk {
		height: 10px;
		width: 60px;
	}


	div.head ul.headm li.headm div.headms div.headmsd {
		display: none;
	}

}

@media (max-width: 1600px) {
	div.head div.headl {
		width: 350px;
		margin-left: 10px;
	}

	div.head div.headm {
		margin-right: 10px;
	}

	div.head div.headr {
		display: none;
	}
}



div.navxinwen {
	width: 25%;
	float: left;
}

div.navxinwen div.navxinweni {
	width: 100%;
	float: left;
	overflow: hidden;
}

div.navxinwen div.navxinweni img.navxinweni {
	width: 100%;
	transition: all 0.5s ease-in-out, color 0.5s ease-in-out;
}

div.navxinwen div.navxinwenn {
	width: 100%;
	background: #ffe100;
	float: left;
}


div.navxinwen div.navxinwenn h3 {
	width: 80%;
	height: 65px;
	margin: auto;
	overflow: hidden;
	line-height: 65px;
	color: #5f5b00;
	font-size: 36px;
	text-transform: uppercase;
	font-weight: bold;
}

div.navxinwen div.navxinwenn h4 {
	width: 80%;
	height: 40px;
	margin: auto;
	overflow: hidden;
	line-height: 40px;
	color: #5f5b00;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 10px;
}

div.navxinwen div.navxinwenn p {
	margin-top: 14px;
	width: 80%;
	height: 48px;
	margin: auto;
	overflow: hidden;
	line-height: 24px;
	color: #000;
	font-size: 14px;
}

div.navxinwen:hover div.navxinweni img.navxinweni {
	-webkit-transform: scale(1.10);
	-moz-transform: scale(1.10);
	-o-transform: scale(1.10);
	transform: scale(1.10);
}

div.navxinwen:hover div.navxinwenn {
	background: #161300;
}

div.navxinwen:hover div.navxinwenn h3 {
	color: #ffe100;
}

div.navxinwen:hover div.navxinwenn h4 {
	color: #ffe100;
}

div.navxinwen:hover div.navxinwenn p {
	color: rgba(255, 255, 0, 0.5);
}
</style>
